<template>
  <div>
    <CRow class="justify-content-between mb-3">
      <CCol sm="auto"
        ><div class="ui-type-display-lg">
          {{ $t("role.finance.invoicesSettings.title") }}
        </div></CCol
      >
    </CRow>

    <CCard>
      <CCardBody>
        <div class="d-flex justify-content-center" v-if="loading">
          <CSpinner color="info" />
        </div>

        <form action="" @submit.prevent="onSubmit" v-if="invoicing">
          <CRow class="align-items-center mb-2">
            <CCol md="4">
              <strong>{{
                $t("role.finance.invoicesSettings.manageInvoices.title")
              }}</strong>
              <p>
                {{
                  $t("role.finance.invoicesSettings.manageInvoices.description")
                }}
              </p>
            </CCol>
            <CCol md="2">
              <CSwitch
                variant="3d"
                size="sm"
                color="secondary"
                :checked.sync="invoicing.manage_payments"
              />
            </CCol>
          </CRow>
          <CRow class="align-items-center mb-2">
            <CCol md="4">
              <strong>{{
                $t("role.finance.invoicesSettings.manageReceipts.title")
              }}</strong>
              <p>
                {{
                  $t("role.finance.invoicesSettings.manageReceipts.description")
                }}
              </p>
            </CCol>
            <CCol md="2">
              <CSwitch
                variant="3d"
                size="sm"
                color="secondary"
                :checked.sync="invoicing.manage_income"
              />
            </CCol>
          </CRow>

          <CRow class="align-items-center mb-2">
            <CCol md="4">
              <strong>{{
                $t("role.finance.invoicesSettings.automate.title")
              }}</strong>
              <p>
                {{ $t("role.finance.invoicesSettings.automate.description") }}
              </p>
            </CCol>
            <CCol md="2">
              <CSwitch
                variant="3d"
                size="sm"
                color="secondary"
                :checked.sync="invoicing.auto_invoice"
              />
            </CCol>
          </CRow>

          <CRow>
            <CCol md="3">
              <CSelect
                :label="
                  $t('role.finance.invoicesSettings.interval.billingInterval')
                "
                :value.sync="invoicing.intervalType"
                :options="intervalTypeOptions"
                :disabled="!invoicing.auto_invoice"
                custom
              >
                <template #label>
                  <label>
                    <strong>{{
                      $t(
                        "role.finance.invoicesSettings.interval.billingInterval"
                      )
                    }}</strong>
                    <em class="text-danger">*</em>
                  </label>
                </template>
              </CSelect>
            </CCol>
            <CCol md="3">
              <CSelect
                v-if="invoicing.intervalType === 1"
                :value.sync="invoicing.intervalSchedule"
                :options="scheduleOptions"
                :disabled="!invoicing.auto_invoice"
                custom
              >
                <template #label>
                  <label>
                    <strong>{{
                      $t("role.finance.invoicesSettings.interval.interval")
                    }}</strong>
                    <em class="text-danger">*</em>
                  </label>
                </template>
              </CSelect>

              <CInput
                v-if="invoicing.intervalType === 2"
                v-model="invoicing.intervalLimitAmount"
                :disabled="!invoicing.auto_invoice"
                :append="currencySymbol"
                type="number"
                step="1"
                min="0"
                custom
              >
                <template #label>
                  <label>
                    <strong>{{
                      $t(
                        "role.finance.invoicesSettings.automate.amountToExceed"
                      )
                    }}</strong>
                    <em class="text-danger">*</em>
                  </label>
                </template>
              </CInput>
            </CCol>
          </CRow>

          <CRow>
            <CCol md="6">
              <CTextarea
                :label="
                  $t('role.finance.invoicesSettings.automate.terms.label')
                "
                v-model="invoicing.payment_terms"
                rows="9"
              />
            </CCol>
          </CRow>

          <vue-ladda
            :loading="saving"
            data-style="zoom-in"
            button-class="btn btn-primary mt-3"
            type="submit"
          >
            {{ $t("role.finance.invoicesSettings.automate.submitButton") }}
          </vue-ladda>
        </form>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import get from "lodash/get";
import { Query } from "@syncfusion/ej2-data";
import { ApiCall, GetDataManager } from "../../../ds";
import { mapGetters } from "vuex";
import {
  AUTO_INVOICE_SCHEDULE,
  AUTO_INVOICE_TYPE,
} from "../../../config/global";
import { parseInvoicingSettings } from "../../../helpers/common";

export default {
  name: "InvoicesSettings",

  data() {
    return {
      loading: false,
      saving: false,
      familyRoleData: null,
      invoicing: null,
    };
  },

  computed: {
    ...mapGetters("connections", ["currencySymbol"]),

    scheduleOptions() {
      return [
        {
          value: AUTO_INVOICE_SCHEDULE.MONTH_BEGINNING,
          label: this.$t(
            "role.finance.settings.scheduleOptions.month_beginning"
          ),
        },
        {
          value: AUTO_INVOICE_SCHEDULE.MONTH_10,
          label: this.$t("role.finance.settings.scheduleOptions.month_10"),
        },
        {
          value: AUTO_INVOICE_SCHEDULE.MONTH_15,
          label: this.$t("role.finance.settings.scheduleOptions.month_15"),
        },
        {
          value: AUTO_INVOICE_SCHEDULE.MONTH_END,
          label: this.$t("role.finance.settings.scheduleOptions.month_last"),
        },
      ];
    },

    intervalTypeOptions() {
      return [
        {
          value: AUTO_INVOICE_TYPE.DATE,
          label: this.$t("role.finance.settings.intervalOptions.basedOnDate"),
        },
        {
          value: AUTO_INVOICE_TYPE.AMOUNT_EXCEEDED,
          label: this.$t(
            "role.finance.settings.intervalOptions.exceedingAmount"
          ),
        },
      ];
    },
  },

  mounted() {
    this.getFamilyRole();
  },

  methods: {
    getFamilyRole() {
      const self = this;
      this.loading = true;

      const roleId = this.$store.state.role.id;
      const dm = GetDataManager("auth_frole", [], roleId);

      ApiCall(
        dm,
        new Query(),
        (response) => {
          self.loading = false;
          self.familyRoleData = response.result;
          const invoicing = get(self.familyRoleData, "extra.invoicing", null);
          this.invoicing = parseInvoicingSettings(invoicing);
        },
        (response) => {
          self.loading = false;
        }
      );
    },

    prepareInvoicing(data) {
      let invoicing = {
        manage_payments: data.manage_payments,
        manage_income: data.manage_income,
        payment_terms: data.payment_terms,
      };
      if (data.auto_invoice === false) {
        invoicing = {
          ...invoicing,
          auto_invoice: null,
        };
      } else {
        if (data.intervalType === AUTO_INVOICE_TYPE.DATE) {
          invoicing = {
            ...invoicing,
            auto_invoice: {
              based_date: data.intervalSchedule,
            },
          };
        } else if (data.intervalType === AUTO_INVOICE_TYPE.AMOUNT_EXCEEDED) {
          invoicing = {
            ...invoicing,
            auto_invoice: {
              based_amount: parseFloat(data.intervalLimitAmount),
            },
          };
        }
      }
      return invoicing;
    },

    getRoleById(roleId) {
      const dm = GetDataManager("auth_role", [], roleId);
      return dm.executeQuery(new Query()).then((res) => {
        return res.result;
      });
    },

    onSubmit() {
      const roleId = this.$store.state.role.id;
      const requestParams = {
        id: roleId,
        extra: {
          ...this.familyRoleData.extra,
          invoicing: this.prepareInvoicing(this.invoicing),
        },
      };

      this.saving = true;
      GetDataManager("auth_frole")
        .update("", requestParams)
        .then(() => {
          this.saving = false;
          this.getRoleById(roleId).then((data) => {
            this.$store.commit("setRole", data);
          });
          this.$store.dispatch("toaster/add", {
            title: "OK!",
            text: this.$t("role.finance.invoicesSettings.success"),
            color: "success",
            autohide: true,
          });
        })
        .catch((error) => {
          console.error(error);
          this.saving = false;
          this.$store.dispatch("toaster/add", {
            title: this.$t("role.finance.invoicesSettings.attention"),
            text: this.$t("role.finance.invoicesSettings.failed"),
            color: "info",
            autohide: true,
          });
        });
    },
  },
};
</script>
